import React, { useState } from 'react';
import { Collapse, Button } from 'reactstrap';

const BlogCollapse = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const data = props.data

    return (
        <div>
            <Collapse isOpen={isOpen}>
                {data.texts.map((d, i) => {
                    return <p key={i} dangerouslySetInnerHTML={ { __html: d } }></p>
                })}
                <p>
                    {data.footer.map((d, i) => {
                        return (<span key={i}>{d} <br /></span>)
                    })}
                </p>
            </Collapse>
            <div className="read-more">
                <Button color="primary" onClick={toggle} style={{ marginTop: '3rem' }} className='telemetry-button'>{isOpen ? 'Mostar menos' : 'Ler mais'}</Button>
            </div>
        </div>
    );
}

export default BlogCollapse;