import React from 'react'

const Breadcrump = props => {
    return (
        <section id="breadcrumbs" className="breadcrumbs">
            <div className="container">

                <div className="d-flex justify-content-between align-items-center notranslate">
                    <h2>{props.title}</h2>
                    <ol>
                        <li><a href="index.html">Home</a></li>
                        <li>{props.title}</li>
                    </ol>
                </div>

            </div>
        </section>
    )
}

export default Breadcrump