import React from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';



const ServiceCard = (props) => {
    AOS.init({
        duration: 2000
    })
    return (
            <div className='row'>
                {props.itens.cards.map((d, i) => {
                    return (
                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="50" key={i} style={{marginTop: '20px'}}>
                            <div className="icon-box iconbox-blue">
                                <div className="icon">
                                    <div className='icon-services'>
                                        
                                        <img src={d.icon} alt=""/>
                                    </div>
                                   
                                </div>
                                <h4>{d.title}</h4>
                                <p>{d.description}</p>
                            </div>
                        </div>
                    )
                })}
        </div>

    )
}

export default ServiceCard