import React from 'react'
import Breadcrump from './little/Breadcrumbs'
import AboutUS from './little/AboutUS'
import SectionTitle from './little/Title'
import texts from '../data/LargeTexts.json'
import CenteredText from './little/CenteredText'
import data from '../data/Team.json'
import Team from './little/Team'

const About = () => {
    return (
        <div>
            <Breadcrump title='Sobre' />
            <section className='about-us'>
                <AboutUS />
            </section>
            <section className='team section-bg'>
                <SectionTitle title='Nosso Time' />
                <CenteredText text={texts.aboutTeam} />
                <div className='container'>

                    <Team itens={data.team}/>
                </div>
            </section>

        </div>
    )
}

export default About