import React from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import BlogCollapse from './BlogCollapse';

const BlogCard = (props) => {
    AOS.init({
        duration: 2000
    })
    return props.posts.map((d, i) => {
        return (
            <article className="entry" data-aos="fade-up" key={i}>

                <div className="entry-img">
                    <img src={d.img} alt="" className="img-fluid" />
                </div>

                <h2 className="entry-title">{d.title}</h2>

                <div className="entry-meta">
                    <ul>
                        <li className="d-flex align-items-center"><i className="icofont-user"></i> {d.author}</li>
                        <li className="d-flex align-items-center"><i className="icofont-wall-clock"></i>{d.date}</li>
                        {/* <li className="d-flex align-items-center"><i className="icofont-comment"></i> <a href="blog-single.html">12 Comments</a></li> */}
                    </ul>
                </div>

                <div className="entry-content">
                    <p>{d.paragraph}</p>
                    <BlogCollapse data={d} />
                </div>

            </article>
        )
    })
}

export default BlogCard