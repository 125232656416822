import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import data from '../../data/Header.json'
import MobileDropdown from './MobileDropdown';

const MobileMenu = (props) => {

    return (
        <div>
            <Modal isOpen={props.visible} className='modal-mobile-menu'>
                <button type="button" className="close close-mobile-menu" aria-label="Close" onClick={() => props.toggle(props.visible)}><span aria-hidden="true">×</span></button>
                {/* <ModalHeader toggle={() => props.toggle(props.visible)}/> */}
                <ModalBody className='notranslate'>
                    {data.itens.map((d, i) => {
                        const classN = window.location.pathname === d.path ? 'mobile-ancor-active' : 'mobile-ancor'
                        let loginPath = d.path
                        if (d.value === 'Login') {
                            loginPath = window.location.href.indexOf('hml') > 1 ? 'https://hml-painel.telemetry.com.br/' : d.path
                        }

                        if (d.dropdown) {
                            return <MobileDropdown key={i} title={d.value} itens={d.dropdownItens} classN={classN} />
                        } else if (d.btn) {
                            return <p key={i}><a href={d.value === 'Login' ? loginPath : d.path} className='header-button' target={d.target}>{d.value}</a></p>
                        }
                        return <p key={i}><a href={d.path} className={classN} target={d.target}>{d.value}</a></p>
                    })}

                </ModalBody>

            </Modal>
        </div>
    );
}

export default MobileMenu;