import React from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Breadcrump from './little/Breadcrumbs'
import SectionTitle from './little/Title';
import midia from '../data/TelemetryMidia.json'


const Contact = props => {
    AOS.init({
        duration: 2000
    })
    return (
        <div>
            <Breadcrump title='Contato' />
            <SectionTitle title='Entre em contato conosco' />

            <section id="contact" className="contact">
                <div className="container">

                    <div className="row justify-content-center" data-aos="fade-up">
                        <div className="col-lg-10">
                            <div className="info-wrap">

                                <div className="row">
                                    <div className="col-lg-4 info">
                                        <i className="icofont-google-map"></i>
                                        <h4>Localização:</h4>
                                        <p>Rua da Pátria, 89<br />Guarulhos, SP</p>
                                    </div>

                                    <div className="col-lg-4 info mt-4 mt-lg-0">
                                        <i className="icofont-envelope"></i>
                                        <h4>Email:</h4>
                                        <p><a href={midia.itens.mail} className='contact-link'>contato@telemetry.com.br</a></p>
                                    </div>

                                    <div className="col-lg-4 info mt-4 mt-lg-0">
                                        <i className="icofont-whatsapp"></i>
                                        <h4>Whastapp</h4>
                                        <p><a href={midia.itens.whatsapp} className='contact-link'>(11) 95813-6300</a></p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <iframe className='forms' title='contact-form' src="https://docs.google.com/forms/d/e/1FAIpQLSdyaa77g5GrG110dVZpvJyP3w_9DqNs0YnnwxrUj0cT4463oA/viewform?embedded=true" width="1100" height="2050" frameborder="0" marginheight="0" marginwidth="0">Carregando…</iframe>
                    </div>

                </div>
            </section>
        </div>

    )
}

export default Contact