import React from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

const Team = (props) => {
    AOS.init({
        duration: 2000
    })
    if (props.blog) {
        return (
            <div style={{marginTop: '-50px'}}>
                {props.itens.map((d, i) => {
                    return (

                        <div key={i}>
                            <div className="member" data-aos="fade-up">
                                <div className="member-img">
                                    <img src={d.photo} className="img-fluid" alt="" />
                                    {d.social.length ? (
                                        <div className="social">
                                            {d.social.map((d, i) => {
                                                return <a href={d.url} target='_blank' rel='noopener noreferrer' key={i}><i className={d.class}></i></a>
                                            })}

                                        </div>

                                    ) : null}
                                </div>
                                <div className="member-info">
                                    <h4>{d.title}</h4>
                                    <span>{d.subTitle}</span>
                                </div>
                            </div>
                        </div>
                    )
                })}

            </div>
        )
    } else {
        return <div className='row'>
        {props.itens.map((d, i) => {
            return (

                <div className="col-lg-3 col-md-6 d-flex align-items-stretch" key={i}>
                    <div className="member" data-aos="fade-up">
                        <div className="member-img">
                            <img src={d.photo} className="img-fluid" alt="" />
                            {d.social.length ? (
                                <div className="social">
                                    {d.social.map((d, i) => {
                                        return <a key={i} href={d.url} target='_blank' rel='noopener noreferrer'><i className={d.class}></i></a>
                                    })}

                                </div>

                            ) : null}
                        </div>
                        <div className="member-info">
                            <h4>{d.title}</h4>
                            <span>{d.subTitle}</span>
                        </div>
                    </div>
                </div>
            )
        })}

    </div>
    }
}

export default Team