import React from 'react'
import data from '../data/Header.json'
import MobileMenu from './little/MobileMenu'
import midia from '../data/TelemetryMidia.json'


class Header extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false
        }
    }

    toggleMenu = v => {
        this.setState({ visible: v ? false : true })
    }

    render() {
        // console.log(window.location.pathname)
        return (
            <header id="header" className="fixed-top">

                <MobileMenu visible={this.state.visible} toggle={this.toggleMenu} />

                {!this.state.visible ? (
                    <button type="button" className="mobile-nav-toggle d-lg-none" onClick={() => this.toggleMenu()}><i className="icofont-navigation-menu"></i></button>
                ) : null
                }
                <div className="container d-flex align-items-center">

                    <a href='/' className='logo mr-auto logo-header'><span className='d-none'>Telemetry</span></a>
                    {/* <!-- Uncomment below if you prefer to use an image logo --> */}
                    {/* <a href="index.html" className="logo mr-auto"><img src="assets/img/logo.png" alt="" className="img-fluid" /></a> */}

                    {!this.state.visible ? (

                        <nav className="nav-menu d-none d-lg-block">
                            <ul className='notranslate'>
                                {data.itens.map((d, i) => {
                                    const classN = window.location.pathname === d.path ? 'active' : ''
                                    let loginPath = d.path
                                    if (d.value === 'Login') {
                                        loginPath = window.location.href.indexOf('hml') > 1 ? 'https://hml-painel.telemetry.com.br/' : d.path
                                    }

                                    if (d.dropdown) {
                                        return <li key={i} className={`drop-down ${classN}`}><a href={d.path}>{d.value}</a>
                                            <ul>
                                                {d.dropdownItens.map((d, i) => {
                                                    return <li key={i}><a href={d.path} target={d.target}>{d.value}</a></li>
                                                })}
                                            </ul>
                                        </li>
                                    } else if (d.btn) {
                                        return  <a key={i} href={d.value === 'Login' ? loginPath : d.path} className='header-button' target={d.target}>{d.value}</a>
                                    }
                                    return <li key={i} className={classN}><a href={d.path} target={d.target}>{d.value}</a></li>
                                })}
                               
                            </ul>
                        </nav>
                    ) : null}

                    <div className="header-social-links">
                        <a href={midia.itens.linkedin} className="linkedin" target='_blank' rel='noopener noreferrer'><i className="icofont-linkedin"></i></a>
                        <a href={midia.itens.instagran} className="instagram" target='_blank' rel='noopener noreferrer'><i className="icofont-instagram"></i></a>
                        {/* <a href={midia.itens.facebook} className="facebook" target='_blank' rel='noopener noreferrer'><i className="icofont-facebook"></i></a> */}
                        <a href={midia.itens.youtube} className="youtube" target='_blank' rel='noopener noreferrer'><i className="bx bxl-youtube"></i></a>
                    </div>


                </div >
            </header >
        )
    }
}

export default Header