import React, { useEffect } from 'react';
import './App.css';
import Header from './components/Header';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Services from './components/Services';
import Home from './components/Home';
import Footer from './components/Footer';
import About from './components/About';
import Blog from './components/Blog';
import Contact from './components/Contact';
import ReactGA from 'react-ga';
// import NotFound from './components/NotFound';

const App = () => {

  useEffect(() => {
    ReactGA.initialize('UA-177602396-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  })

  return (
    <BrowserRouter>
      <Header />

      <div className='content-site'>
        <Switch>

          <Route
            path="/solutions"
            component={() => (
              <Services />
            )}
          />
          <Route
            path="/about"
            component={() => (
              <About />
            )}
          />
          <Route
            path="/blog"
            component={() => (
              <Blog />
            )}
          />
          <Route
            path="/contact"
            component={() => (
              <Contact />
            )}
          />


          {/* 
            Fazer a 404
            <Route component={NotFound} /> 
            */}

          <Route
            path="/"
            component={() => (
              <Home />
            )}
          />
        </Switch>

        <Footer />
      </div>
    </BrowserRouter>
  );

}

export default App;
