import React from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import data from '../../data/About.json'


const AboutUS = () => {
    AOS.init({
        duration: 2000
    })

    return (
        
            <div className="container" data-aos="fade-up">

                <div className="row content">
                    <div className="col-lg-6" data-aos="fade-right">
                        <h2>{data.title}</h2>
                        <h3>{data.subTitle}</h3>
                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0" data-aos="fade-left">
                        <p>{data.content}</p>
                        {data.itens.length ? (
                            <ul>
                                {data.itens.map((d, i) => {
                                    return <li key={i}><i className="ri-check-double-line"></i>{d}</li>
                                })}

                            </ul>
                        ) : null}

                        <p className="font-italic">{data.subContent}</p>
                    </div>
                </div>

            </div>
        
    )
}

export default AboutUS