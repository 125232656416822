import React from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import data from '../data/Solutions.json'
import Breadcrump from './little/Breadcrumbs';
import SectionTitle from './little/Title';
import CenteredText from './little/CenteredText';
import texts from '../data/LargeTexts.json'

const Services = () => {
    AOS.init({
        duration: 2000
    })
    return (
        <div>

            <div className='services section-bg' id='services'>
                <Breadcrump title='Soluções' />
                <SectionTitle title='Uma Startup que desponta no mercado' />
                <CenteredText text={texts.solutions} />
                <div className='container solutions'>
                    {data.itens.map((d, i) => {
                        return (
                            <div className='solutions-case' key={i}>
                                <div className="align-items-stretch" data-aos="zoom-in" data-aos-delay="50" style={{ marginTop: '20px' }}>
                                    <div className="icon-box iconbox-blue">
                                        <div className="icon">
                                            <div className='icon-services'>
                                                <img src={d.icon} alt="" />
                                            </div>

                                        </div>
                                        <h4>{d.title}</h4>
                                        <p>{d.description}</p>
                                    </div>
                                </div>
                                <img src={d.img} className='solutions-img ' data-aos="zoom-in" data-aos-delay="50" alt="" />
                            </div>
                        )
                    })}
                    

                </div>
            </div>
        </div>
    )
}

export default Services