import React from 'react'
import midia from '../data/TelemetryMidia.json'

const Footer = () => {
    return (
        <footer id='footer'>

            <div className="footer-top">
                <div className="container">

                    <div className='d-lg-none d-xl-none' style={{ marginBottom: '30px' }}>

                        <div className='d-flex justify-content-center'>
                            <div className='footer-logo'></div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-lg-4 col-md-6 col-12 footer-contact container-footer-logo">
                            <div>

                                <h3>ONDE ESTAMOS</h3>

                                <p>Rua da Pátria, 89</p>
                                <p>Jardim Vermelhão, Guarulhos - SP</p>
                                <p>CEP: 07210-360 </p>
                                <br />
                                <a href={midia.itens.whatsapp} className='footer-link'>(11) 95813-6300</a>
                                <br/>
                                <a href={midia.itens.mail} className='footer-link notranslate'>contato@telemetry.com.br</a>
                            </div>
                        </div>

                        <div className="d-none d-lg-block d-xl-none d-xl-block col-lg-4 col-12 container-footer-logo">
                            <div className='footer-logo'></div>
                        </div>


                        <div className="col-lg-4 col-md-6 col-12 footer-newsletter container-footer-logo">
                            <div style={{ maxWidth: '300px' }}>
                                <h4>Newsletter Telemetry!</h4>
                                <p>Cadastre seu e-mail e seja o primeiro a descobrir tudo o que podemos fazer para sua empresa ou negócio.</p>
                                <form id='form-none mc-embedded-subscribe-form' action="https://telemetry.us19.list-manage.com/subscribe/post?u=a4f8787eef9aa8dab56bbe2e4&amp;id=f5426642c4" method="post" name="mc-embedded-subscribe-form" className="validate" target="_blank"
                                    noValidate>
                                    <div className='newsletter-form'>
                                        <div aria-hidden="true"><input type="text" name="b_a4f8787eef9aa8dab56bbe2e4_f5426642c4" tabIndex="-1" className='d-none' style={{ width: '0px' }} /></div>
                                        <input type="email" className="email input-newsletter" placeholder="Seu Email" name="EMAIL" id="mce-EMAIL" required />
                                        <button className="btn btn-secondary btn-xl header-button newsletter-btn">Cadastrar</button>
                                    </div>
                                </form>
                            </div>


                        </div>

                    </div>
                </div>
            </div>

            <div className="container d-md-flex py-4">

                <div className="mr-md-auto text-center text-md-left">
                    <div className="copyright notranslate">
                        &copy; Telemetry Todos os Direitos Reservados {new Date().getFullYear()}
                    </div>

                </div>
                <div className="social-links text-center text-md-right pt-3 pt-md-0">
                    <a href={midia.itens.whatsapp} className="whatsapp" target='_blank' rel='noopener noreferrer'><i className="bx bxl-whatsapp"></i></a>
                    <a href={midia.itens.facebook} className="facebook" target='_blank' rel='noopener noreferrer'><i className="bx bxl-facebook"></i></a>
                    <a href={midia.itens.instagran} className="instagram" target='_blank' rel='noopener noreferrer'><i className="bx bxl-instagram"></i></a>
                    <a href={midia.itens.linkedin} className="linkedin" target='_blank' rel='noopener noreferrer'><i className="bx bxl-linkedin"></i></a>
                    <a href={midia.itens.youtube} className="youtube" target='_blank' rel='noopener noreferrer'><i className="bx bxl-youtube"></i></a>
                </div>
            </div>

        </footer>


    )
}

export default Footer