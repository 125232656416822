import React from 'react'
import BlogCard from './little/BlogCard'
import Breadcrump from './little/Breadcrumbs'
import socialMidia from '../data/SocialMidia.json'
import blogSocialMidia from '../data/BlogSocialMidia.json'
import Team from './little/Team';
import data from '../data/Blog.json'

const Blog = props => {
    return (
        <div>
            <Breadcrump title='Blog' />
            <section id="blog" className="blog">
                <div className='container'>
                    <div className='row'>
                        <div className="col-lg-8 entries">
                            <BlogCard posts={data.posts}/>
                        </div>

                        <div className="col-lg-4">
                            <div className="sidebar" data-aos="fade-left">
                                <h3 className="sidebar-title">Nossas Mídias</h3>
                                <section className='team'>
                                    <Team itens={socialMidia.itens} blog={true}/>
                                    <Team itens={blogSocialMidia.itens} blog={true}/>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Blog