import React from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

const SectionTitle = (props) => {
    AOS.init({
        duration: 2000
    })

    return (
        <div className="section-title" data-aos="zoom-in">
            <h2>{props.title}</h2>
            {props.descripiton ? <p>{props.descripiton}</p> : null}
        </div>
    )
}

export default SectionTitle