import React from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

const ButtonA = props => {
    AOS.init({
        duration: 2000
    })
    return (
        <div className='text-center' data-aos="zoom-in">
            <a
                className="button-primary-a"
                target={props.data.target}
                href={props.data.href}
                rel='noopener noreferrer'>
                {props.data.title}
            </a>
        </div>
    )
}

export default ButtonA