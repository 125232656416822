import React from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';


const GaleryLogo = (props) => {
    AOS.init({
        duration: 2000
    })
    var className = 'row no-gutters clients-wrap clearfix center-galery-logo';
    console.log(props.itens.length)
    if(props.itens.length > 4) {
        className = 'row no-gutters clients-wrap clearfix'
    }
    
    return (
        <div className="container" data-aos="zoom-in">
            <div className={className} data-aos="zoom-in">
                {props.itens.map((d, i) => {
                    return (
                        <div className="col-lg-4 col-6 " key={i}>
                            <div className="client-logo">
                                {props.links ? (
                                    <a href={props.links[i]} target="_blank" rel="noopener noreferrer"><img src={d} className="img-fluid" alt="" /></a>
                                ) : (<img src={d} className="img-fluid" alt="" />)}
                            </div>
                        </div>
                    )
                })}

            </div>
        </div>
    )
}


export default GaleryLogo