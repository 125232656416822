import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const MobileDropdown = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className='mobile-dropdown'>
      <DropdownToggle caret className={props.classN}>
        {props.title}
      </DropdownToggle>
      <DropdownMenu>
        {props.itens.map((d, i) => {
          const classN = window.location.pathname === d.path ? 'mobile-ancor-active' : 'mobile-ancor'
          return <DropdownItem key={i}><a href={d.path} className={classN} target={d.target}>{d.value}</a></DropdownItem>
        })}
      </DropdownMenu>
    </Dropdown>
  );
}

export default MobileDropdown;