import React from 'react';
import Slider from './little/Slider';
import AboutUS from './little/AboutUS';
import SectionTitle from './little/Title';
import ServiceCard from './little/ServiceCard';
import CenteredText from './little/CenteredText';
import ButtonA from './little/ButtonA';
import GaleryLogo from './little/GaleryLogo';
import Team from './little/Team';

//data
import slider from '../data/Slider.json'
import servicesCards from '../data/ServicesCard.json'
import texts from '../data/LargeTexts.json'
import listLogos from '../data/ListsLogos.json'
import socialMidia from '../data/SocialMidia.json'



class Home extends React.Component {
    
    render() {
        return (
            <div>
                <Slider data={slider} />
                <section className='about-us'>
                    <SectionTitle title='Sobre nós' />
                    <AboutUS />

                </section>
                <section className='services section-bg' id='services'>
                    <div className='container'>
                        <SectionTitle title='Serviços' />
                        <ServiceCard itens={servicesCards} />
                    </div>
                </section>

                <section className='portifolio'>
                    <SectionTitle title='ATUAMOS PRÓXIMO DO NOSSO CLIENTE' />
                    <CenteredText text={texts.clientsText} />
                </section>

                <section className='section-bg'>
                    <SectionTitle title='CONTE-NOS SOBRE A SUA EMPRESA' />
                    <ButtonA
                        data={{
                            href: 'https://forms.gle/MD9H61NDMpPXC5XK7',
                            title: 'Formulário de Atendimento',
                            target: '_blank'
                        }} />
                </section>

                <section className='clients'>
                    <SectionTitle title='NOSSOS CLIENTES ' />
                    <GaleryLogo itens={listLogos.clients} />
                    <SectionTitle title='NOSSOS PARCEIROS ' />
                    <GaleryLogo itens={listLogos.partners} />
                    <SectionTitle title='TELEMETRY NA MÍDIA' />
                    <GaleryLogo 
                        itens={listLogos.media} 
                        links={
                            [
                                "https://blogdocaminhoneiro.com/2020/11/artigo-queda-na-disponibilidade-de-motoristas-profissionais-no-mercado-brasileiro/",
                                "https://cargapesada.com.br/2020/11/26/transporte-aquece-e-aumenta-falta-de-motoristas/",
                                "https://www.aracoiabadaserra.com.br/queda-na-disponibilidade-de-motoristas-profissionais-no-mercado-brasileiro/"
                            ]
                        }/>
                </section>

                <section className='team section-bg'>
                    <SectionTitle title='Nossas Mídias' />
                    <div className='container-fluid container-social-midia'>
                        <Team itens={socialMidia.itens} />
                    </div>
                </section>

            </div>
        )
    }
}

export default Home