import React from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

const CenteredText = (props) => {
    AOS.init({
        duration: 2000
    })
    return (
        <div className='container' data-aos="fade-up">
            <p>{props.text}</p>
        </div>
    )
}

export default CenteredText